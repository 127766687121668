import "./index.css";
function HomeAboutPage() {
  return (
    <div className="container-fluid home_about_page">
      <div className="row home_about_row">
        <h1>About Delnie</h1>
      </div>
      <div className="row home_about_row">
        <p>
          Starting its journey in 2017. Delnie is an India-based company and has
          been a part of the I.T Industry by developing Innovative Products. We
          have closely collaborated with our clients to build trusted
          relationships by providing efficient business solutions and customised
          products. We today function in two business units - Delnie Innovation
          Lab with the purpose of building efficient SAAS Products and Delnie
          Consultancy Service to provide the best IT services to your business
          by integrating and enhancing existing solutions in the market
        </p>
      </div>
      <div className="row home_learn_more_row">
        <a href="/about-us" className="about_us_link">
          Learn More {""}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="29"
            viewBox="0 0 30 29"
            fill="none"
          >
            <path
              d="M29.0361 1.99953C29.0361 1.1212 28.3241 0.40917 27.4458 0.40917L13.1325 0.409169C12.2542 0.409169 11.5422 1.1212 11.5422 1.99953C11.5422 2.87786 12.2542 3.58989 13.1325 3.58989L25.8554 3.58989L25.8554 16.3128C25.8554 17.1911 26.5674 17.9031 27.4458 17.9031C28.3241 17.9031 29.0361 17.1911 29.0361 16.3128L29.0361 1.99953ZM3.12456 28.5699L28.5703 3.12409L26.3212 0.874975L0.875445 26.3208L3.12456 28.5699Z"
              fill="#CCCCCC"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
export default HomeAboutPage;
