import "./index.css";
import montainer from "../../../assets/images/about/montainer.png";
import bulb from "../../../assets/images/about/about_desktop.png";

import bulbmobile from "../../../assets/images/about/about_mobile_banner.png";
import poland from "../../../assets/images/about/poland.png";
import saudi from "../../../assets/images/about/saudi.png";
import singapore from "../../../assets/images/about/singapore.png";
import india from "../../../assets/images/about/india.png";
import australia from "../../../assets/images/about/australia.png";
import { useEffect } from "react";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container-fluid about_page_full">
        <div className="container-flex about_page_top">
          <img
            src={bulb}
            className="img-fluid about_banner_desktop"
            alt=""
            width="100%"
            height="auto"
          ></img>
          <img
            src={bulbmobile}
            className="img-fluid about_banner_mobile"
            alt=""
            width="100%"
            height="auto"
          ></img>
          <div className="centered">
            <span className="page-title">About Us</span>
          </div>
        </div>
      </div>
      <div className="container-fluid about_page">
        <div className="row about_row2">
          <p className="about_para section-content">
            Starting its journey in 2017. Delnie is an India-based company and
            has been a part of the I.T Industry by developing Innovative
            Products. We have closely collaborated with our clients to build
            trusted relationships by providing efficient business solutions and
            customised products. We today function in two business units -
            Delnie Innovation Lab with the purpose of building efficient SAAS
            Products and Delnie Consultancy Service to provide the best IT
            services to your business by integrating and enhancing existing
            solutions in the market
          </p>
        </div>

        <div className="row mission">
          <div className="col-lg-2  col-md-3 col-sm-12 mv_head">
            <p className="mission_hd section-title">Mission</p>
          </div>
          <div className="col-lg-10  col-md-9 col-sm-12 mv_content">
            <p className="mission_para section-content">
              To be an integral part of your business ideas and transform them
              into reality by analysing your ideas and providing innovative
              solutions, and integrating technology and thus be a part of your
              vision.
            </p>
          </div>
        </div>
        <div className="row vision">
          <div className="col-lg-2 col-md-3  col-sm-12 mv_head">
            <h1 className="vision_hd section-title">Vision</h1>
          </div>
          <div className="col-lg-10 col-md-9  col-sm-12 mv_content">
            <p className="vision_para section-content">
              Making technology and affordable automated solutions and products
              to all kinds of enterprises irrespective of their nature and
              scalability.
            </p>
          </div>
        </div>
        <div className="row our_purpose_row">
          <p className="our-purpose-title section-title">Our Purpose</p>
        </div>
        <div className="row purpose">
          <p className="purpose_para section-content">
            Break the complexities by providing feasible solutions through
            advanced technologies and constant support for all your business
            needs
          </p>
        </div>
        <div className="row our_purpose_row">
          <p className="our-purpose-title section-title">What Do We Do?</p>
        </div>
        <div className="row purpose">
          <p className="purpose_para section-content">
            At Delnie, product development forms a cornerstone of our business
            technology practice. Today, our primary focus is to provide a
            platform for exploring innovative and unique ideas and developing
            them into efficient products. We are a step ahead in understanding
            and analysing the market and providing the right solutions and
            strategies.
          </p>
        </div>
        {/* <div className="row what-we-do-row">
      <img
        src={montainer}
        alt=""
        width="100%"
        height="auto"
        className="img-fluid what-we-do-img"
      ></img>
      <div className="container what-we-do-section">
        <div className="row what-we-do-title-row">
          <h1 className="what-we-do-title section-title">What Do We Do?</h1>
        </div>
        <div className="row">
          <p className="what-we-do-content section-content">
            At Delnie, product development forms a cornerstone of our
            business technology practice. Today, our primary focus is to
            provide a platform for exploring innovative and unique ideas and
            developing them into efficient products. We are a step ahead in
            understanding and analysing the market and providing the right
            solutions and strategies.
          </p>
        </div>
      </div>
    </div> */}
        <div className="row about_row4">
          <h1 className="caption_centre3 section-title">
            Values we believe in
          </h1>
        </div>
        <div className="row about_row5">
          <div className="col-lg-4 col-sm-12 check">
            <video width="320" controls>
              <source
                src={require("../../../assets/videos/check.mp4")}
                type="video/mp4"
              />
            </video>
          </div>
          <div className="col-lg-8 col-sm-12 values">
            <div className="row values_row1">
              <p className="values_hd">Always do your best</p>
            </div>
            <div className="row values_row2">
              <p className="values_para section-content">
                No forecast is perfect, but try anyway. Respect and encourage
                people. Always be learning & Transparent
              </p>
            </div>
            <div className="row values_row1">
              <p className="values_hd">Integrity</p>
            </div>
            <div className="row values_row2">
              <p className="values_para section-content">
                We will be fair, honest, transparent and ethical in our conduct;
                everything we do must stand the test of public scrutiny.
              </p>
            </div>
            <div className="row values_row1">
              <p className="values_hd">Excellence</p>
            </div>
            <div className="row values_row2">
              <p className="values_para section-content">
                We are passionate about achieving the highest standards of
                quality, always promoting meritocracy.
              </p>
            </div>
          </div>
        </div>
        <div className="row our-presence-row">
          <h1 className="our-presence-title section-title">Our Presence</h1>
        </div>
        <div className="row about_row6">
          <div className="col-2">
            <img src={poland} alt="" width="100%" height="auto"></img>
          </div>
          <div className="col-2">
            <img src={saudi} alt="" width="100%" height="auto"></img>
          </div>
          <div className="col-2">
            <img src={singapore} alt="" width="100%" height="auto"></img>
          </div>
          <div className="col-2">
            <img src={india} alt="" width="100%" height="auto"></img>
          </div>
          <div className="col-2">
            <img src={australia} alt="" width="100%" height="auto"></img>
          </div>
        </div>
        <div className="row about_row2">
          <p className="presence_para section-content">
            In a short span of time, Delnie has developed multiple solutions
            like RMS, Zactel and Delnie Books, bringing quality changes to the
            world. Today, we are a well-equipped, constantly improving company
            providing IT solutions like smart billing solutions, accounting
            applications and many more. We are spreading our wings across the
            globe with clients in India, Poland, Singapore, Saudi Arabia and
            Australia.
          </p>
        </div>
      </div>
    </>
  );
}
export default About;
