import { Link } from "react-router-dom";
import "./index.css";
import cloudadminimage from "../../../../assets/images/products/cloud_admin_image.png";

function ProductDelnieCloudAdmin() {
  return (
    <div className="container-fluid favhiker_tab">
      <div className="row favhiker_content_header">
        <h1>Your Tailored SaaS Gateway for Streamlined Data Management </h1>
        <p>
          Delnie Cloud Admin: A Customized SaaS Solution for Effortless Data
          Management, Tailored to Enhance Business Efficiency with Intuitive
          Tools and Secure, Scalable Features for Companies of All Sizes
        </p>
      </div>
      <div className="row cloud_products_details">
        <div className="col-lg-6 com-sm-12 cloud_products_image_col">
          <img src={cloudadminimage} alt="" className="img-fluid" />
        </div>
        <div className="col-lg-6 col-sm-12 cloud_products_details_col">
          <h1>Key Features</h1>
          <ul>
            <li>
              Customizable Dashboard : Personalized interface to view and manage
              data relevant to specific business needs.{" "}
            </li>
            <li>
              Data Integration: Seamless integration with various data sources
              for centralized data management.{" "}
            </li>
            <li>
              User Management:Role-based access control to ensure secure and
              appropriate data access for different team members.
            </li>
            <li>
              Maintenance Support:Continuous support and system updates to
              ensure smooth operation.
            </li>
          </ul>

          <Link to={"/contact_us"}>
            <button>Enquiry Now</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProductDelnieCloudAdmin;
