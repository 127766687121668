import React, { useState } from "react";
import axios from "axios";
import { FormGroup, TextField, Button, Checkbox } from "@mui/material";

import "./index.css";
import { custom_post, post } from "../../../../utils/api";

export default function ContactUsForm() {
  const [message, setMessage] = useState<any>({ message: "", status: false });
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone_number: "",
    company: "",
    subject: "",
    description: "",
  });
  const [errorState, setErrorState] = useState({
    name: "",
    email: "",
    phone_number: "",
    company: "",
    subject: "",
    description: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetForm = () => {
    setFormState({
      name: "",
      email: "",
      phone_number: "",
      company: "",
      subject: "",
      description: "",
    });
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log("formState", formState);
    if (validateForm(formState, errorState, setErrorState)) {
      // let url = "https://api-crm.delnie.com/api/WebsiteEnquiries";
      // axios
      //   .post(url, formState, {
      //     headers: {
      //       "api-key": "ASXASXSAZXSAZXWQWDXSDFDSXDSXDC",
      //       email: "chr@deefe.com",
      //       password: "tesrt@5",
      //       "Content-Type": "application/json",
      //     },
      //   })
      //   .then(() => {
      //     setMessage({
      //       message: "Thank you for your submission! we will get back you soon",
      //       status: true,
      //     });
      //     resetForm();
      //     setIsSubmitting(false);
      //   })
      //   .catch(() => {
      //     setMessage({
      //       message: "Oops! Something went wrong. Please try again.",
      //       status: false,
      //     });
      //     setIsSubmitting(false);
      //   });

      try {
        const response = await post("delnie_enquiries", formState);

        const mailToUser = custom_post("delnie_mail_user", { ...formState });

        const mailToAdmin = custom_post("delnie_mail_admin", { ...formState });
        if (response) {
          resetForm();
          setIsSubmitting(false);
          console.log("Form submitted successfully");
        } else {
          console.error("Form submission failed");
        }
      } catch (error) {
        setIsSubmitting(false);
        console.error("Error submitting form:", error);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <form className="get-in-touch-section" onSubmit={handleSubmit}>
      <div className="row contact_form_section">
        <h1 className="get-in-touch">Get in touch</h1>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <TextField
            label="Full Name"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            InputProps={{
              style: { color: "#fff" },
            }}
            required
            className="get-in-touch-input"
            name="name"
            value={formState.name}
            onChange={handleChange}
            error={Boolean(errorState.name)}
            helperText={errorState.name}
          />
          {/* <input type="text" className="form-control get-in-touch-input" name="name" onChange={handleChange} placeholder="Full Name" /> */}
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <TextField
            label="Company Name"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            InputProps={{
              style: { color: "#fff" },
            }}
            required
            className="get-in-touch-input"
            name="company"
            value={formState.company}
            onChange={handleChange}
            error={Boolean(errorState.company)}
            helperText={errorState.company}
          />
          {/* <input type="text" className="form-control get-in-touch-input" name="company" onChange={handleChange} placeholder="Company name" /> */}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6">
          {/* <input type="text" className="form-control get-in-touch-input" name="email" onChange={handleChange} placeholder="Email" /> */}
          <TextField
            label="Email"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            InputProps={{
              style: { color: "#fff" },
            }}
            type="email"
            required
            className="get-in-touch-input"
            name="email"
            value={formState.email}
            onChange={handleChange}
            error={Boolean(errorState.email)}
            helperText={errorState.email}
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <TextField
            label="Phone No"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            InputProps={{
              style: { color: "#fff" },
            }}
            type="text"
            required
            className="get-in-touch-input"
            name="phone_number"
            value={formState.phone_number}
            onChange={handleChange}
            error={Boolean(errorState.phone_number)}
            helperText={errorState.phone_number}
          />
          {/* <input type="text" className="form-control get-in-touch-input" name="phone_number" onChange={handleChange} placeholder="Contact number" /> */}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TextField
            label="Subject"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            InputProps={{
              style: { color: "#fff" },
            }}
            type="text"
            required
            className="get-in-touch-input"
            name="subject"
            value={formState.subject}
            onChange={handleChange}
            error={Boolean(errorState.subject)}
            helperText={errorState.subject}
          />
          {/* <input type="text" className="form-control get-in-touch-input" name="subject" onChange={handleChange} placeholder="Subject eg: Custom ERP" /> */}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TextField
            label="Description"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            InputProps={{
              style: { color: "#fff" },
            }}
            multiline
            rows={4}
            type="text"
            required
            className="get-in-touch-input"
            name="description"
            value={formState.description}
            onChange={handleChange}
            error={Boolean(errorState.description)}
            helperText={errorState.description}
          />
          {/* <textarea rows={4} className="form-control get-in-touch-input" name="description" onChange={handleChange} placeholder="Description"> */}
          {/* </textarea> */}
        </div>
      </div>
      <div className="row">
        {/* <input type="submit" className="col-12 submit-btn center" value="Submit" /> */}
        <button
          className={`submit-btn center ${isSubmitting ? "loading" : ""}`}
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {isSubmitting ? "Submiting..." : "Submit"}
        </button>
        {message.status ? (
          <div className="col-12">
            <div className="alert alert-success" role="alert">
              {message.message}
            </div>
          </div>
        ) : (
          <div className="col-12">
            <div className="alert alert-error" role="alert">
              {message.message}
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

export function validateForm(
  formState: any,
  errorState?: any,
  setErrorState?: any
) {
  const newErrorState = { ...errorState };

  if (!formState.email) {
    newErrorState.email = "Email is required  ";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formState.email)
  ) {
    newErrorState.email = "Invalid email address";
  } else {
    newErrorState.email = "";
  }

  if (!formState.phone_number) {
    newErrorState.phone_number = "Phone number is required  ";
  } else if (!/^[0-9]{10}$/i.test(formState.phone_number)) {
    newErrorState.phone_number = "Invalid phone number";
  } else {
    newErrorState.phone_number = "";
  }

  if (!formState.name) {
    newErrorState.name = "First name is required  ";
  } else {
    newErrorState.name = "";
  }
  if (!formState.company) {
    newErrorState.company = "Company name is required  ";
  } else {
    newErrorState.company = "";
  }
  if (!formState.subject) {
    newErrorState.subject = "Subject is required  ";
  } else {
    newErrorState.subject = "";
  }
  if (!formState.description) {
    newErrorState.description = "Description is required  ";
  } else {
    newErrorState.description = "";
  }
  setErrorState(newErrorState);
  return Object.values(newErrorState).every((error) => error === "");
}
