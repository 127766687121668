import { FC, useContext, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import App from "../App";

import Footer from "../modules/common/footer";
import Header from "../modules/common/header";
import Home from "../modules/components/home";

import ApplicationForm from "../modules/components/squad/application-form";

import Squad from "../modules/components/squad/squad";
import Products from "../modules/components/products/products";
import ContactUs from "../modules/components/contact-us";

import Navbar from "../modules/common/Navbar/Navbar";
import About from "../modules/components/about-us";

const AppRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <>
              <Navbar />
              {/* <Header /> */}
              <App />
              <Footer />
            </>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/product-folio" element={<Products />} />
          <Route path="/squad" element={<Squad />} />
          <Route path="/application-form" element={<ApplicationForm />} />
          <Route path="/contact_us" element={<ContactUs />} />
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/home" index element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
