import ContactUsForm from "../../components/contact-us/contact-form";
import "./index.css";
import LogoFooter from "../../../assets/images/logo/logo-black.svg";
import LinkedIn from "../../../assets/images/footer/linkedin.svg";
import Facebook from "../../../assets/images/footer/facebook.svg";
import Instagram from "../../../assets/images/footer/instagram.svg";
import X from "../../../assets/images/footer/twitter.svg";
import Youtube from "../../../assets/images/footer/youtube.svg";
import Lottie from "../../../assets/images/footer/linkedin.svg";

function Footer() {
  return (
    <div className="container-fluid footer_body">
      <div className="row ftr_2">
        <div className="col-sm-12 col-md-12 col-lg-7">
          <div className="row nbr">
            <ul className="nav">
              <li className="nav-item">
                <a
                  className="nav-link index-menu"
                  aria-current="page"
                  href="index"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link about-us-menu" href="about-us">
                  About Us
                </a>
              </li>
              <li className=" nav-item">
                <a className="nav-link product-folio-menu" href="product-folio">
                  Product folio
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link squad-menu" href="squad">
                  The Squad
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row del-locations">
        <div className="col-lg-6 part1">
          <div className="row part2">
            <div className="col-lg-6 col-md-6 col-sm-12 address">
              <div className="del-card">
                <div className="lottie-loc">
                  {/* <lottie-player src="res/lottie/loc-lotttie.json" background="transparent" speed="1" style="width: 50px; height: 50px;" loop autoplay></lottie-player> */}
                </div>
                <h1 className="del-city">Bangalore</h1>
                <p className="del-address">
                  Delnie Innovation Lab <br />
                  5th Floor, WeWork Galaxy Building <br />
                  43, Residency Rd, Shanthala Nagar,
                  <br />
                  Ashok Nagar, Bengaluru,
                  <br />
                  Karnataka 560025
                  <br />
                  CIN - U74999MH2017PTC300138
                  <br />
                  GSTIN - 29AAGCD3699B1ZG
                  <br />
                  Email : christo.john@delnie.com
                  <br />
                  Mob : +91 9846280132
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 address">
              <div className="del-card">
                <div className="lottie-loc">
                  {/* <Lottie
                    src={Lottie}
                    background="transparent"
                    speed="1"
                    style="width: 50px; height: 50px;"
                    loop
                    autoplay
                  ></Lottie> */}
                </div>
                <h1 className="del-city">Mumbai</h1>

                <p className="del-address">
                  Delnie Consultancy Services <br />
                  Raheja Platinum, <br />
                  Marol CHS Rd,Andheri - Kurla Road, <br />
                  Marol, Andheri East, Mumbai, <br />
                  Maharashtra 400059 CIN - U74999MH2017PTC300138 <br />
                  GSTIN - 27AAGCD3699B1ZK <br />
                  Email : info@delnie.com <br />
                  Mob : +91 8356984779
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-6">
          <ContactUsForm />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-3  col-lg-3 logo-ftr-cntnr">
          <a href="https://www.delnie.com">
            <img className="title-logo" src={LogoFooter} />
          </a>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-2 social-cntnr">
          <span className="social">
            <a target="new" href="https://www.linkedin.com/company/delnie/">
              <img className="vector" src={LinkedIn} />
            </a>
            <a target="new" href="https://www.facebook.com/delnie.official/">
              <img className="vector" src={Facebook} />
            </a>
            <a target="new" href="https://www.youtube.com/@delnie_official">
              <img className="vector img-fluid" src={Youtube} />
            </a>
            <a
              target="new"
              href="https://www.instagram.com/delnie_official?igsh=MXRmNW84d2xjbWhmMQ=="
            >
              <img className="vector" src={Instagram} />
            </a>
          </span>
        </div>
        <div className="col-sm-12 col-md-5 col-lg-2 privacy-link-cntnr">
          <a href="privacy-policy" className="piracy-link">
            <p>Privacy Policy</p>
          </a>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 copyright-cntnr">
          <p className="copyright">
            © Copyright 2017 - 2024 Delnie Pvt Ltd. All Rights Reserved.
          </p>
        </div>
        <div className="col-sm-12 col-md-1 col-lg-1 copyright-cntnr">
          <p className="copyright"></p>
        </div>
      </div>
    </div>
  );
}
export default Footer;
