import "./index.css";
import Productimageone from "../../../../assets/images/products/products_banner_favhiker.png";
import Productimagetwo from "../../../../assets/images/products/products_banner_cloud.png";

function OurProducts() {
  return (
    <div className="container-fluid our_products_page index-page-products">
      <div className="row products_image_row">
        <div className="col-lg-6">
          <img src={Productimageone} alt="" className="img-fluid" />
        </div>
        <div className="col-lg-6">
          <img src={Productimagetwo} alt="" className="img-fluid" />
        </div>
      </div>
      {/* <div className="row products_page_header_row">
        <h1>Choose from existing suite of products</h1>
      </div> */}
    </div>
  );
}
export default OurProducts;
