import "./index.css";

import jSQimg from "../../../../assets/images/squad/squad-image.png";
import Arrowlink from "../../../../assets/images/squad/arrowlink_blue.png";

function JoinSquad() {
  return (
    <div className="container-fluid join-squad-row">
      <div className="row rect">
        <div className="col-lg-4 col-md-4 climber">
          <img
            src={jSQimg}
            className="squad-img img-fluid"
            alt="squad-image"
          ></img>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12">
          <div className="row HDDG">
            <h1 className="join">
              Join the <span className="cap-squad">SQUAD</span>
            </h1>
          </div>
          <div className="row content">
            <p>
              You don’t have to be the expectional one to join our squad, we at
              Delnie believe in having the drive to learn new things and the
              curiosity to explore new oppurtunitites. We hire the ones working
              towards perfection.
            </p>
          </div>
          <div className="row openings">
            <a href="squad">
              <button className="squad-button">
                Explore Openings <img src={Arrowlink} alt="arrowlink"></img>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default JoinSquad;
